<template>
  <div data-cy="wizard-context">
    <b-row no-gutters>
      <b-col>
        <h1 class="text-uppercase text-center">
          MeetLouis
        </h1>

        <h2 class="text-capitalize text-center">
          Say goodbye to those boring SEO tasks And effortlessly boost your article's ranking
        </h2>
      </b-col>
    </b-row>

    <div>
      <div class="bg-dark rounded-lg p-4 mt-50 ml-auto mr-auto" style="max-width: 825px">
        <img src="@/assets/character/smiling.png" class="float-left" width="100" />
        <p class="bigger">
          All right!<br />
          Let's write an SEO blog post right now.<br />
        </p>

        <h5 class="text-uppercase">Or Describe The Blog Post You Need To Write...</h5>
        <form-builder
          :state-machine="stateMachine"
          :components-list="componentsList"
          :custom-validators="{}"
          :next-button="{ text: 'Continue', variant: 'success' }"
          :is-sending="isSending"
          @submit="nextStep"
          class="form-wizard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TRIGGER_EVENT } from "@/store/actions";

import { FormBuilder } from "ui-stepper";
import { BRow, BCol } from "bootstrap-vue";
import cloneDeep from "lodash/cloneDeep";

import { LANDING_STATE_MACHINE_SEO_POST } from "@/constants/project";

export default {
  name: "LandingSettings",
  components: { BRow, BCol, FormBuilder },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    stateMachine: null,
    componentsList: {
      Message: () => ({
        component: import("@/components/Forms/TipTap/Paragraph.vue"),
        timeout: 3000,
      }),
      SeoKeywords: () => ({
        component: import("@/components/Forms/Base/SeoKeywords.vue"),
        timeout: 3000,
      }),
    },

    value: "",

    isLoading: false,
    isSending: false,
  }),
  created() {
    this.stateMachine = cloneDeep(LANDING_STATE_MACHINE_SEO_POST);
  },
  methods: {
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
    async nextStep(value) {
      this.isLoading = true;

      if (!value) {
        const { formData } = this.stateMachine.context;
        value = cloneDeep(formData);
      }

      this.$emit("update:blogPost", value);
      this.$emit("next-step");

      this.isLoading = false;
    },

    handleCurrentStep(value) {
      this.triggerEvent({ event: "landing_step", step: value, vueInstance: this });
      this.currentStepIndex = value;
    },
    handleUseSuggestion() {},
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_settings";

.landing-context {
  small {
    font-size: 0.7em;
    display: block;
    color: $gray-darker;
  }
}

legend {
  width: auto;
  font-size: 0.8em;
  color: gray;
  font-weight: 600;
  margin: 0;
}

fieldset {
  border: 2px solid rgb(246 246 246);
  padding: 10px 25px 25px 25px;
  margin-bottom: 1em;
}
</style>

<style>
#form-lp-goodbyeseo .tiptap-paragraph,
#form-lp-goodbyeseo .base-input {
  border: 1px solid #151718;
  border-radius: 25px;
}
</style>
